export const bannerHeaderVar = {
	hidden: {
		opacity: 0,
		scale: 1.001,
		y: -0.1
	},
	visible: {
		opacity: 1,
		scale: 1,
		y: 0,
		transition: { duration: 1.66 }
	}

};

export const movingImgVar = {
	hidden: { scale: 1.3 },
	visible: {
		scale: 1.1,
		y: [ 15, -5, 0 ],
		x: [ -15, 13, 0 ],
		transition: {
			duration: 18,
			repeat: Infinity,
			repeatType: 'reverse'
		}
	}
};

export const carouselVar = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
		transition: {
			duration: 0.9,
			ease: 'easeInOut',
			delayChildren: 0.3,
			staggerChildren: 0.3
		}
	}
};

export const carouselInfoVar = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
		y: [ 5, -2, 0 ],
		transition: {
			duration: 0.9,
			type: 'spring',
			ease: 'anticipate',
			delayChildren: 0.3,
			staggerChildren: 0.3
		}
	}
};

export const testimonialVar = {
	hidden: { opacity: 0.5 },
	visible: {
		opacity: 1,
		scale: [ 1.02, 1, 1.02 ],
		transition: {
			duration: 1.5,
			ease: 'easeIn'
		}
	}
};

export const testimonialCredVar = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
		transition: {
			duration: 0.9,
			delayChildren: 0.3,
			staggerChildren: 0.3
		}
	}
};

export const testimonialChildrenVar = {
	hidden: { opacity: 0 },
	visible: { opacity: 1, transition: { duration: 0.9, ease: 'easeIn' } }
};

export const dropDownSectionVar = {
	hidden: {
		opacity: 0
		// height: 0
	},
	visible: {
		opacity: 1
		// height: '3rem'
	}
};

export const scrollVar = {
	hidden: { opacity: 0.7 },
	visible: { opacity: 1 }
};

export const featureVar = {
	hidden: { x: 30 },
	visible: { x: 0 }
};

export const featureBannerVar = {
	hidden: { opacity: 0.9 },
	visible: { opacity: 1, transition: { delayChildren: 0.3, staggerChildren: 0.2 } }
};

export const float = {
	hidden: {
		opacity: 0,
		scale: 1.02,
		y: 12
	},
	visible: {
		opacity: 1,
		scale: 1,
		y: [ 12, -3, 2, 0 ]
	}
};

export const fade = {
	hidden: { opacity: 0 },
	visible: { opacity: 1 }
};

export const animation = ( { scale, y, x } ) => ( {
	scale,
	y,
	x,
	transition: {
		duration: 30,
		repeat: Infinity,
		repeatType: 'mirror'
	}
} );
