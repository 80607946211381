import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

import { movingImgVar, carouselVar, carouselInfoVar } from '../../../utils/framerVariants';

import InView from '../../../components/InView';

const ContactTemplate = ( { locations } ) => {
	const [ activeCity, setActiveCity ] = useState( locations[ 0 ] );
	const [ index, setIndex ] = useState( 0 );

	const clickHandler = e => {
		const locationIndex = locations.indexOf( locations.find( loc => loc.address.city === e.currentTarget.textContent ) );

		setIndex( locationIndex );
		setActiveCity( locations[ locationIndex ] );
	};

	return (
		<>
			<div>
				<div className="contactWrapper">
					<AnimatePresence>
						<motion.div key={index} variants={carouselVar} initial="hidden" animate="visible" className="contactCarousel">
							<motion.img variants={movingImgVar} src={ activeCity.image.childImageSharp ? activeCity.image.childImageSharp.fluid.src : activeCity.image } alt={ activeCity.address.city } />
							<div className="overlay"/>
							<motion.div variants={carouselVar} initial="hidden" animate="visible" className="info">
								<motion.div variants={carouselInfoVar} initial="hidden" animate="visible">
									{ activeCity.address.phone && <span className="overline">{ activeCity.address.street }</span> }
									{ activeCity.address.fax && <span className="overline">{ activeCity.address.street }</span> }
									<span className="overline">{ activeCity.address.street }</span>
									<span className="overline">{ activeCity.address.suite }</span>
									<span className="overline">{ activeCity.address.city }, { activeCity.address.state } { activeCity.address.zip }</span>
								</motion.div>
							</motion.div>
						</motion.div>
					</AnimatePresence>
					<div className="cityTabs">
						{locations.map( ( loc, i ) => (
							<React.Fragment key={ i }>
								<div
									className={ `tab ${ activeCity.address.city === loc.address.city ? 'text-primary-300' : '' }` }
									onClick={ clickHandler }
								>
									<p className="overline">{ loc.address.city }</p>
								</div>
								{ i !== locations.length - 1 && <div className="divider" /> }
							</React.Fragment>
						) )}
					</div>
				</div>
			</div>
		</>
	);
};

ContactTemplate.propTypes = { locations: PropTypes.array };

export default ContactTemplate;
