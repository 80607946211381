import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const InView = ( { y, scale, children, ...props } ) => {
	const [ ref, isVisible ] = useInView( { threshold: 0.3 } );

	const variants = {
		hidden: {
			opacity: 0.85,
			y,
			scale
		},
		visible: {
			opacity: 1,
			y: 0,
			scale: 1,
			transition: {
				opacity: { duration: 0.8 },
				y: { duration: 0.5 },
				scale: { duration: 0.9 },
				ease: 'easeInOut',
				delayChildren: 1.5,
				staggerChildren: 0.5
			}
		}
	};

	return (
		<motion.div ref={ref} variants={variants} animate={isVisible ? 'visible' : 'hidden'} {...props}>
			{children}
		</motion.div>
	);
};

InView.defaultProps = {
	y: -2,
	scale: 1.1
};

InView.propTypes = {
	children: PropTypes.node.isRequired,
	y: PropTypes.number,
	scale: PropTypes.number
};

export default InView;
